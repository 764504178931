<template>
  <q-dialog v-model="show" persistent>
        <q-card class="form" >
          <q-card-section class="text-center text-white text-bold bg-light-blue-6">
            Login User
          </q-card-section>
          <q-card-section class="">

            <q-input outlined v-model="user.username" id="id" placeholder="username" @keyup.enter="$refs.pass.focus()" dense>
              <template v-slot:append>
                <q-icon name="account_circle" @click="text = ''" class="cursor-pointer" />
              </template>
            </q-input>
            <q-input outlined v-model="user.password" ref="pass" :type="isPwd ? 'password' : 'text'" id="password" placeholder="Password"  @keyup.enter="login()" dense>
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions  align="center" class="q-py-lg">
            <q-btn outline  style="width: 120px;" color="light-blue-6" label="Batal" @click="$router.push('/')" no-caps/>
            <q-btn style="width: 120px;color:white;" color="light-blue-6" label="Masuk" no-caps @click="login()"/>
          </q-card-actions>
        </q-card>
  </q-dialog>
</template>

<script>
import md5 from 'md5'
export default {
  name: "Login",
  data: function() {
    return {
      user: {},
      isPwd: true,
      show: true,
    };
  },
  methods: {
    login(){
      var usr = {}
      usr.username = this.user.username
      usr.password = md5(this.user.password)
      this.$http.post('/login', usr, {})
      .then (async result => {
        localStorage.setItem('smp-afbs', result.data)

        if(this.$q.screen.width < 500)
          await this.$router.push('/m-pendaftaran')
        else
          await this.$router.push('/pendaftaran')
        
        this.$q.notify({
          icon: "done",
          color: "positive",
          message: "Anda berhasil login",
        });

        await this.$router.go('/pendaftaran')
      })
      .catch(err => {
        this.$q.notify({
          icon: "warning",
          color: "negative",
          message: "Username atau password salah",
        });
        
      })
    }
  },
};
</script>
<style lang="scss" scoped>

.form{
  width: 300px; 
}
label{
  padding-top: 10px; padding-bottom: 15px;
  color: #505050; font-weight: bold;
}
</style>
